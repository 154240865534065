.footer.lighttheme{
    padding: 24px 0;

    &.footer-default{
        background-color: $dark-color !important;
    }

    nav{
        display: inline-block;
        float: left;
        padding-left: 0;
    }

    ul{
        margin-bottom: 0;
        padding: 0;
        list-style: none;

        li{
            display: inline-block;

            a{
                color: inherit;
                padding: $padding-base-vertical;
                font-size: $font-size-small;
                text-transform: uppercase;
                text-decoration: none;

                &:hover{
                    text-decoration: none;
                }
            }
        }
    }

    .copyright{
        font-size: $font-size-small;
        line-height: 1.8;
    }

    &:after{
        display: table;
        clear: both;
        content: " ";
    }
}

.footer.bluelighttheme{
    padding: 24px 0;
    background-color: #ffffff !important;

    &.footer-default{
        background-color: $dark-color !important;
    }

    nav{
        display: inline-block;
        float: left;
        padding-left: 0;
    }

    ul{
        margin-bottom: 0;
        padding: 0;
        list-style: none;

        li{
            display: inline-block;

            a{
                color: inherit;
                padding: $padding-base-vertical;
                font-size: $font-size-small;
                text-transform: uppercase;
                text-decoration: none;

                &:hover{
                    text-decoration: none;
                }
            }
        }
    }

    .copyright{
        font-size: $font-size-small;
        line-height: 1.8;
    }

    &:after{
        display: table;
        clear: both;
        content: " ";
    }
}

.footer.darktheme{
    padding: 24px 0;
    background-color: $red-color !important;
    color: #ddd !important;
    &.footer-default{
        background-color: $red-color !important;
        color: #ddd !important;
    }

    nav{
        display: inline-block;
        float: left;
        padding-left: 0;
    }

    ul{
        margin-bottom: 0;
        padding: 0;
        list-style: none;

        li{
            display: inline-block;

            a{
                color: inherit;
                padding: $padding-base-vertical;
                font-size: $font-size-small;
                text-transform: uppercase;
                text-decoration: none;

                &:hover{
                    text-decoration: none;
                }
            }
        }
    }

    .copyright{
        font-size: $font-size-small;
        line-height: 1.8;
    }

    &:after{
        display: table;
        clear: both;
        content: " ";
    }
}

.footer.greytheme{
    padding: 24px 0;
    background-color: $grey-theme-color !important;
    color: #ddd !important;
    &.footer-default{
        background-color: $grey-theme-color !important;
        color: #ddd !important;
    }

    nav{
        display: inline-block;
        float: left;
        padding-left: 0;
    }

    ul{
        margin-bottom: 0;
        padding: 0;
        list-style: none;

        li{
            display: inline-block;

            a{
                color: inherit;
                padding: $padding-base-vertical;
                font-size: $font-size-small;
                text-transform: uppercase;
                text-decoration: none;

                &:hover{
                    text-decoration: none;
                }
            }
        }
    }

    .copyright{
        font-size: $font-size-small;
        line-height: 1.8;
    }

    &:after{
        display: table;
        clear: both;
        content: " ";
    }
}

.atextdecoration.darktheme {
    color: #ddd !important;
}

.atextdecoration.greytheme {
    color: #ddd !important;
}

.atextdecoration.lighttheme {
    color: black !important;
}