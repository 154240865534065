.background {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(202, 150, 150, 0.2);
    z-index: 1 !important;
}

.background > .wrapper {
    position: fixed;
    top: calc(50% - 30px);
    left: 50%;
}

.background > .wrappers {
    position: fixed;
    top: calc(50% - 150px);
    width: calc(100% - -200px) !important;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.nav-open{
    .main-panel{
        .background > .wrappers{
            position: fixed;
            top: calc(20%);
            width: 100% !important;
            display: flex;
            flex-direction: column;
            align-items: center;
        }
    }
}

.background > .wrapper > .loading {
    margin-left: -34px;
    margin-top: 40px;
    color: black;
    font-size: 18px;
    font-weight: bold;
}

.background > .wrappers > .loading {
    color: black;
    font-size: 18px;
    font-weight: bold;
}

@-webkit-keyframes ball-scale-multiple {
    0% {
      -webkit-transform: scale(0);
      transform: scale(0);
      opacity: 0; }
    5% {
      opacity: 1; }
    100% {
      -webkit-transform: scale(1);
      transform: scale(1);
      opacity: 0; } }

@keyframes ball-scale-multiple {
    0% {
      -webkit-transform: scale(0);
      transform: scale(0);
      opacity: 0; }
    5% {
      opacity: 1; }
    100% {
      -webkit-transform: scale(1);
      transform: scale(1);
      opacity: 0; } }

.ball-scale-multiple {
    position: relative;
    -webkit-transform: translateY(-30px);
    transform: translateY(-30px); }
.ball-scale-multiple > div:nth-child(2) {
    -webkit-animation-delay: -0.4s;
    animation-delay: -0.4s; }
.ball-scale-multiple > div:nth-child(3) {
    -webkit-animation-delay: -0.2s;
    animation-delay: -0.2s; }
.ball-scale-multiple > div {
    background-color: #2B303B;
    border-radius: 100%;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    position: absolute;
    left: -30px;
    top: 0;
    opacity: 0;
    margin: 0;
    width: 60px;
    height: 60px;
    -webkit-animation: ball-scale-multiple 1s 0s linear infinite;
    animation: ball-scale-multiple 1s 0s linear infinite; }


.App-logo {
    height: 8vmin;
    pointer-events: none;
    margin: 2rem;
    margin-left: 6rem;
}

.App-logo-gif {
    height: 20vmin;
    pointer-events: none;
}
    
@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 5s linear;
    }
}

@keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
}


.background > .loading {
    color: #212120;
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    width: auto;
    height: 100px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
}

@media screen and (max-width: 990px) {
    .App-logo {
        height: 8vmin;
        pointer-events: none;
        margin: 2rem;
        margin-left: -2rem;
    }

    .background > .wrapper > .loading {
        margin-left: -130px;
        margin-top: 40px;
        color: black;
        font-size: 14px;
        font-weight: bold;
    }

    .background > .wrappers {
        position: fixed;
        top: calc(50% - 150px);
        width: calc(100%) !important;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .background > .wrappers > .loading {
        color: black;
        font-size: 14px;
        font-weight: bold;
        text-align: center
    }

    .App-logo-gif {
        height: 13vmin;
        pointer-events: none;
    }
}

@media screen and (max-width: 650px) {
    .App-logo-gif {
        height: 18vmin;
        pointer-events: none;
    }
}