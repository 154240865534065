.remote-tab-align {
padding: 29px 68px;
overflow: hidden;
position: relative;
font-size: 0.875rem;
max-width: 264px;
min-width: 72px;
box-sizing: border-box;
min-height: 48px;
text-align: center;
flex-shrink: 0;
font-family: "Roboto", "Helvetica", "Arial", sans-serif;
font-weight: 500;
line-height: 1.75;
white-space: normal;
letter-spacing: 0.02857em;
text-transform: uppercase;
}