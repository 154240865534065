.buttons-excel{
    color: #fff !important;
    background: #4285f4 !important;
    border: none !important;
}
.login-box {
    padding: 1rem !important;
    width: 450px !important;
}
.thermocard{
    width: 300px;
    margin: 0 auto;
}
.selectedthermo{
    float: left;
  
}
.thermocard td{
    width: 100px;
    margin: 0 auto;
    font-size: 10px;
}
.thermocard td i{
    font-size: 26px;
    padding: 2px;
}
  
.login-box-text {
   text-align: center;
   font-family: 'Segoe UI' !important;
}
  
.login-bg {
    background: url('../images/nzv2new.jpg') center center/cover no-repeat !important;
    height: 100% !important;
    position: fixed;
    width: 100% !important;
}

.atextdecoration, .atextdecoration:hover {
    text-decoration: none !important;
    color: black;
    cursor: pointer;
}

.login-button {
    margin: 0.5rem 0rem 1rem 0rem;
}

.set-button {
    margin: 0.5rem 0rem 1rem 1.5rem;
}

.error{
    color: red;
}

.Formfieldcss {
    display: contents !important;
    width: 100% !important;
}

.authfontfamily {
    font-family: 'Montserrat' !important;
    font-size: 17px !important;
    padding: 1rem !important;
}

.authfontfamily2 {
    font-family: 'Montserrat' !important;
    font-size: 12px !important;
}

.dialog{
    height: 310px;
      width: 300px;
      /* margin: 28px 22% 0px; */
      margin: 0px auto;
 
  
   
   
  }
  .dialog img{
    position: center;
    object-fit: cover;
 
  }
  .dialog div{
    position: relative;
    top: -206px;
    /* left: -25px; */
    /* text-align: center; */
    color: white;
  
  }
  .dialog div span{
      font-size: 45px;
  }
  .tapHead{
    padding-left:4px;
  }
  .tapsubtitle{
    padding-left: 6px;
    font-weight: 600;
  }