
 .sidebar-wrapper{
    overflow-x: hidden !important;
    overflow-y: auto !important;
  }
/* scrolbar */
/* .sidebar-wrapper */
.sidebar-wrapper:hover::-webkit-scrollbar {
    width: 8px;
    background: grey !important;
} 
.sidebar-wrapper::-webkit-scrollbar {
    width: 0px;
    background: grey !important;
    /* display: none; */
} 
  
  /* Track */
.sidebar-wrapper::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    background: grey !important;
}
   
  /* Handle */
 .sidebar-wrapper::-webkit-scrollbar-thumb {
    background:#AAAAA7 !important;
    border-radius: 10px;

}
  
  /* Handle on hover */
.sidebar-wrapper::-webkit-scrollbar-thumb:hover{
    background: #AAAAA7 !important;
  }





