.segmented-progress-bar {
    display: flex;
    width: 75px; /* Adjust the width as needed */
    height: 5px; /* Adjust the height as needed */
    background-color: #d6a5a5; /* Background color of the progress bar */
    border-radius: 5px; /* Rounded corners for the progress bar */
    overflow: hidden; /* Hide overflowing segments */

  }
  
  .segment {
    flex: 1;
    background-color: #007bff; /* Color of the active segments */
    height: 100%;
  }
  
  .sactive {
    background-color: #c5d0dd; /* Color of the active segments */
  }
  .CircularProgressbar {
    box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.5); /* Customize the shadow style */
  border-radius: 50%;
  animation: emboss 2s infinite alternate;
  }

  .circular-progressbar-container {
  width: 100px; /* Set your desired width */
  height: 100px; /* Set your desired height */
  position: relative;
  border-radius: 50%; /* Ensure the container is circular */
  background: linear-gradient(
    135deg,
    rgba(255, 255, 255, 0.2) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.2) 50%,
    rgba(255, 255, 255, 0.2) 75%,
    transparent 75%,
    transparent
  ); /* Create a diagonal gradient background to simulate the embossing effect */
  animation: emboss 2s infinite alternate; /* Apply an animation for a subtle animation effect */
}

@keyframes emboss {
  0% {
    box-shadow: inset 0 0 60px rgb(193 35 35 / 54%); /* Initial shadow */
  }
  100% {
    box-shadow: inset 0 0 60px rgba(255, 255, 255, 0.2); /* Final shadow */
  }
}

.circular-progressbar-container .progressbar-color {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%; /* Ensure the color follows the circular shape */
}

.circular-progressbar-container .progressbar-shadow {
  position: absolute;
  width: 100%;
  height: 100%;
  box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.5); /* Customize the shadow style */
  border-radius: 50%; /* Ensure the shadow follows the circle shape */
  pointer-events: none; /* Allows interaction with the progress bar */
}



/* Swing */

.toggle-switch {
    display: flex;
    align-items: center;
    gap: 5px;
    cursor: pointer;
  }
  
  .switch {
    position: relative;
    display: inline-block;
    width: 30px;
    height: 15px;
  }
  
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 30px;
  }
  
  .slider:before {
    position: absolute;
    content: '';
    height: 12px;
    width: 12px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 50%;
  }
  
  input:checked + .slider {
    background-color: #2196F3;
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(15px);
  }
  
  .on {
    color: #2196F3;
  }
  
  .off {
    color: #ccc;
  }
  

  .modal-content{
    background-color:transparent !important;
    box-shadow: none !important;
    /* box-shadow:0 5px 0px 0 rgba(0,0,0,0.18), 0 4px 0px 0 rgba(0,0,0,0.15) !important; */
  }


  /* Add any custom styles you need for the close button here */
.close-button {
    cursor: pointer;
    display: inline-block;
  }
  
  .close-button svg {
    fill: red; /* Change the fill color as needed */
  }
  
  .close-button svg circle {
    fill: transparent;
    stroke: red; /* Change the border color as needed */
    stroke-width: 2;
  }
  