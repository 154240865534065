/*--------------------------------

nucleo-icons Web Font - built using nucleoapp.com
License - nucleoapp.com/license/

-------------------------------- */
@font-face {
  font-family: 'nucleo-icons';
  src: url('./fonts/nucleo-icons.eot');
  src: url('./fonts/nucleo-icons.eot') format('embedded-opentype'), url('./fonts/nucleo-icons.woff2') format('woff2'), url('./fonts/nucleo-icons.woff') format('woff'), url('./fonts/nucleo-icons.ttf');
  font-weight: normal;
  font-style: normal;
}
