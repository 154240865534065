.wave-progress-container {
  max-width: 200px; /* Adjust the max-width as needed */
  /* margin: 50px auto; */
  text-align: center;
  color: #FFF;
}

.wave-progress-bar {
  position: relative;
  width: 45px; /* Adjust the width to control the thickness of the progress bar */
  height: 370px; /* Adjust the height as needed */
  background-color: rgb(230, 217, 217);
  border-radius: 50px;
  overflow: hidden;
  /* margin: 20px auto; */
  text-align: center;
}

.wave {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background: linear-gradient(to top, #90e0ef,#023e8a);
  transition: height 0.5s ease-in-out; /* Smooth transition for the wave effect */
  text-align: center;
}

.progress-label {
  color: #FFF;
  font-size: 16px;
  font-weight: bold;
}
