body {
  margin: 0;
  font-family: -apple-system, 'Montserrat','Nunito Sans','Segoe UI', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

span.usernamefont {
  font-family: 'Josefin Sans' !important;
  font-weight: 500 !important;
}
#realtimechartdiv,.highcharts-container ,.chartContainer,#highcharts-ql2uu01-0 , .highcharts-background ,.highcharts-root{
  width: 100% !important;
}
a.navbar-brand {
  font-family: 'Sora' !important;
  font-weight: 400 !important;
}

code {
  font-family: -apple-system, 'Montserrat','Nunito Sans','Segoe UI', sans-serif;
}

.card-category.newsize {
  font-size: 22px !important;
}

.newsizetext {
  font-size: 40px !important;
  font-family: 'Rubik' !important;
}

.card-stats .icon-big {
  font-size: 4em !important;
}

.card label {
  font-size: 1.1em !important;
  font-family: 'Rubik' !important;
}

.atextdecoration {
  text-decoration: none !important;
  cursor: pointer;
}
.chartContainer {
  width: auto !important;
  height: 500px !important;
}

.footer {
  background-color: #f4f3ef;
  box-shadow: none;
  border-top: 1px solid #ddd;
  font-size: 20px;
  color: black;
}

footer.footer a {
  color: black;
}

.changepassword {
  text-align: center;
}

.card-category {
  font-size: 14px !important;
}

.numbers {
  text-align: right;
  font-size: 1.5em !important;
}

.e-input-group:not(.e-success):not(.e-warning):not(.e-error):not(.e-float-icon-left), .e-input-group.e-float-icon-left:not(.e-success):not(.e-warning):not(.e-error) .e-input-in-wrap, .e-input-group.e-control-wrapper:not(.e-success):not(.e-warning):not(.e-error):not(.e-float-icon-left), .e-input-group.e-control-wrapper.e-float-icon-left:not(.e-success):not(.e-warning):not(.e-error) .e-input-in-wrap, .e-float-input.e-float-icon-left:not(.e-success):not(.e-warning):not(.e-error) .e-input-in-wrap, .e-float-input.e-control-wrapper.e-float-icon-left:not(.e-success):not(.e-warning):not(.e-error) .e-input-in-wrap {
  border: 1px solid rgba(0,0,0,0.42) !important;
  padding: 0.75rem !important;
  border-radius: 5px !important;
}

.e-input-group .e-clear-icon, .e-input-group.e-control-wrapper .e-clear-icon {
  display: none !important;
}

.nbztable {
  border-collapse: collapse;
  width: 100%;
  overflow-x:auto !important;
  table-layout: fixed !important;
  word-wrap:break-word !important;
  font-family: 'Sora' !important;
}

.nbztable .icons{
  font-size: 15px !important;
}
.nbztable th{
  padding: 20px 15px;
  text-align: center;
  font-weight: 800;
  color: #fbc658 !important;
  text-transform: uppercase;
  font-size: 15px !important;
  border: 1px solid  #212120;
  background-color: #212120;
  font-family: 'Sora' !important;
  font-weight: 500 !important;
}
.nbztable td{
  padding: 15px;
  text-align: center;
  vertical-align:middle;
  font-weight: 500;
  font-size: 14px;
  color: #000;
  border-bottom: solid 1px  #337ab7;
  font-family: 'Sora' !important;
  font-weight: 400 !important;
}

/* lightheme */
.nbztable.lighttheme.blackcolor th{
  padding: 20px 15px;
  text-align: center;
  font-weight: 800;
  color: #fbc658 !important;
  text-transform: uppercase;
  font-size: 15px !important;
  border: 1px solid  #fbc658;
  background-color: #212120;
  font-family: 'Sora' !important;
  font-weight: 500 !important;
}
.nbztable.lighttheme.blackcolor td{
  padding: 15px;
  text-align: center;
  vertical-align:middle;
  font-weight: 500;
  font-size: 14px;
  color: #000;
  border-bottom: solid 1px  #337ab7;
  font-family: 'Sora' !important;
  font-weight: 400 !important;
}

.table-responsive.lighttheme.blackcolor::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  background: whitesmoke   !important;
}

.fixTableHead {
  overflow-y: auto;
  height: 110px;
}
.fixTableHead thead th {
  position: sticky;
  top: 0;
}
/* Track */
.table-responsive.lighttheme.blackcolor::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  background: whitesmoke   !important;
}
 
/* Handle */
.table-responsive.lighttheme.blackcolor::-webkit-scrollbar-thumb {
  background: #212120   !important;
}

/* Handle on hover */
.table-responsive.lighttheme.blackcolor::-webkit-scrollbar-thumb:hover {
  background: #212120   !important;
}
.MuiPaper-root.MuiStepper-root.MuiStepper-horizontal.card-user-stepper.lighttheme.blackcolor.MuiPaper-elevation0 {
  background-color: #ffffff !important;
}

div.dataTables_wrapper div.dataTables_info, div.dataTables_wrapper div.dataTables_paginate {
  font-weight: 400;
  font-family: 'Sora';
}

.Flex-div.blackcolor::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  background: whitesmoke   !important;
} 

/* Track */
.Flex-div.blackcolor::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  background: whitesmoke   !important;
}
 
/* Handle */
.Flex-div.blackcolor::-webkit-scrollbar-thumb {
  background: #212120   !important;
}

/* Handle on hover */
.Flex-div.blackcolor::-webkit-scrollbar-thumb:hover {
  background: #212120   !important;
}

/* grey color */
.nbztable.greytheme.greycolor th{
  padding: 20px 15px;
  text-align: center;
  font-weight: 800;
  color: #fbc658 !important;
  text-transform: uppercase;
  font-size: 15px !important;
  border: 1px solid  #4B4B4C;
  background-color: #282a2e;
  font-family: 'Sora' !important;
  font-weight: 500 !important;
}
.nbztable.greytheme.greycolor td{
  padding: 15px;
  text-align: center;
  vertical-align:middle;
  font-weight: 500;
  font-size: 14px;
  background-color: #282a2e !important;
  color: #fff;
  border-bottom: solid 1px  #4B4B4C;
  font-family: 'Sora' !important;
  font-weight: 400 !important;
}

.table-responsive.greytheme.greycolor::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  background: #222327   !important;
} 

/* Track */
.table-responsive.greytheme.greycolor::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  background: #222327   !important;
}
 
/* Handle */
.table-responsive.greytheme.greycolor::-webkit-scrollbar-thumb {
  background: #4B4B4C   !important;
}

/* Handle on hover */
.table-responsive.greytheme.greycolor::-webkit-scrollbar-thumb:hover {
  background: #4B4B4C   !important;
}

.card-user.greytheme.greycolor {
  background-color: #282a2e !important;
  color: #fff !important;
}

.table-responsive.greytheme.greycolor .dataTables_info {
  color: #fff !important;
}

.table-responsive.greytheme.greycolor .dataTables_filter label {
  color: #fff !important;
}

.table-responsive.greytheme.greycolor .dataTables_wrapper .dataTables_paginate .paginate_button {
  color: #fff !important;
  font-family: 'Sora' !important;
  font-weight: 400 !important;
}

.table-responsive.greytheme.greycolor .dataTables_wrapper .dataTables_paginate .paginate_button.current {
  color:black !important;
  font-family: 'Sora' !important;
  font-weight: 400 !important;
}

/* blue color */

.nbztable.lighttheme.bluecolor th{
  padding: 20px 15px;
  text-align: center;
  font-weight: 800;
  color: #ffffff !important;
  text-transform: uppercase;
  font-size: 15px !important;
  border: 1px solid  #ffffff;
  background-color: #23a6d5;
  font-family: 'Sora' !important;
  font-weight: 500 !important;
}
.nbztable.lighttheme.bluecolor td{
  padding: 15px;
  text-align: center;
  vertical-align:middle;
  font-weight: 500;
  font-size: 14px;
  color: #000;
  border-bottom: solid 1px  #23a6d5;
  font-family: 'Sora' !important;
  font-weight: 400 !important;
}

.table-responsive.lighttheme.bluecolor::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  background: whitesmoke   !important;
} 

/* Track */
.table-responsive.lighttheme.bluecolor::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  background: whitesmoke   !important;
}
 
/* Handle */
.table-responsive.lighttheme.bluecolor::-webkit-scrollbar-thumb {
  background: #23a6d5   !important;
}

/* Handle on hover */
.table-responsive.lighttheme.bluecolor::-webkit-scrollbar-thumb:hover {
  background: #23a6d5   !important;
}
.MuiPaper-root.MuiStepper-root.MuiStepper-horizontal.card-user-stepper.lighttheme.bluecolor.MuiPaper-elevation0 {
  background-color: #ffffff !important;
}

.Flex-div.bluecolor::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  background: whitesmoke   !important;
} 

/* Track */
.Flex-div.bluecolor::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  background: whitesmoke   !important;
}
 
/* Handle */
.Flex-div.bluecolor::-webkit-scrollbar-thumb {
  background: #23a6d5   !important;
}

/* Handle on hover */
.Flex-div.bluecolor::-webkit-scrollbar-thumb:hover {
  background: #23a6d5   !important;
}

/* darktheme */
.nbztable.darktheme.whitecolor th{
  padding: 20px 15px;
  text-align: center;
  font-weight: 800;
  color: #fbc658 !important;
  text-transform: uppercase;
  font-size: 15px !important;
  border: solid 1px #337ab7;
  background-color: rgba(29,27,65) !important;
  font-family: 'Sora' !important;
  font-weight: 500 !important;
}

.nbztable.darktheme.whitecolor td{
  padding: 15px;
  text-align: center;
  vertical-align:middle;
  font-weight: 500;
  font-size: 14px;
  background-color: rgba(29,27,65) !important;
  color: #fff;
  border-bottom: solid 1px  #337ab7;
  font-family: 'Sora' !important;
  font-weight: 400 !important;
}

.nbztable.darktheme.whitecolor.dataTable.no-footer td{
  padding: 15px;
  text-align: center;
  vertical-align:middle;
  font-weight: 500;
  font-size: 14px;
  color: #fff;
  background-color: rgba(29,27,65) !important;
  border-bottom: solid 1px  #337ab7;
  font-family: 'Sora' !important;
  font-weight: 400 !important;
}

.table-responsive.darktheme.whitecolor::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  background: #21658c   !important;
} 

/* Track */
.table-responsive.darktheme.whitecolor::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  background: #21658c   !important;
}
 
/* Handle */
.table-responsive.darktheme.whitecolor::-webkit-scrollbar-thumb {
  background: #fbc658   !important;
}

/* Handle on hover */
.table-responsive.darktheme.whitecolor::-webkit-scrollbar-thumb:hover {
  background: #fbc658   !important;
}

.card-user.darktheme.whitecolor {
  background-color: rgba(29,27,65,.6) !important;
  color: #fff !important;
}

.table-responsive.darktheme.whitecolor .dataTables_info {
  color: #fbc658 !important;
}

.table-responsive.darktheme.whitecolor .dataTables_filter label {
  color: #fbc658 !important;
}

.table-responsive.darktheme.whitecolor .dataTables_wrapper .dataTables_paginate .paginate_button {
  font-family: 'Sora' !important;
  font-weight: 400 !important;
  color: #fbc658 !important;
}

.table-responsive.darktheme.whitecolor .dataTables_wrapper .dataTables_paginate .paginate_button.current {
  color:black !important;
  font-family: 'Sora' !important;
  font-weight: 400 !important;
}

/* end */

:host /deep/ button.dt-button.buttons-excel.buttons-html5 {
  background-image: linear-gradient(to bottom, #4285f4 0%, #4285f4 100%) !important;
  color: white !important;
}

.table-responsive {
  overflow: auto !important;
}

.Flex-div {
  height: 500px;
  overflow-y: auto !important;
  overflow-x: scroll !important;
  width: auto;
}

.Flex-Box-div {
  width: max-content;
}

.darktheme.dropdown-menu.dropdown-menu-right.show {
  background-color: #21658c !important;
}

.greytheme.dropdown-menu.dropdown-menu-right.show {
  background: #282a2e !important;
}

.lighttheme.dropdown-menu.dropdown-menu-right.show {
  background: #ffffff !important;
}

a.atextdecoration.darktheme.dropdown-item:hover {
  background-color: rgb(29, 27, 65) !important;
  color: #fbc658 !important;
}

a.atextdecoration.greytheme.dropdown-item:hover {
  background-color: #222327 !important;
  color: #fbc658 !important;
}

a.atextdecoration.lighttheme.blackcolor.dropdown-item:hover {
  background: #212120 !important;
    color: #fbc658 !important;
}

a.atextdecoration.lighttheme.bluecolor.dropdown-item:hover {
  background-color: #23a6d5  !important;
  color: #fbc658 !important;
}

.darktheme.dropdown-menu.dropdown-menu-right.show:after {
  border-bottom: 11px solid #21658c !important;
}

.greytheme.dropdown-menu.dropdown-menu-right.show:after {
  border-bottom: 11px solid #222327 !important;
}

.lighttheme.dropdown-menu.dropdown-menu-right.show:after {
  border-bottom: 11px solid #ffffff;
}

.sidebar .sidebar-wrapper.darktheme li.active > a:not([data-toggle="collapse"]):after, .sidebar .sidebar-wrapper.darktheme li.active > [data-toggle="collapse"] + div .nav li:after {
  border-right: 17px solid #21658c;
}

.sidebar .sidebar-wrapper.greytheme li.active > a:not([data-toggle="collapse"]):after, .sidebar .sidebar-wrapper.greytheme li.active > [data-toggle="collapse"] + div .nav li:after {
  border-right: 17px solid #222327;
}

.sidebar .sidebar-wrapper.lighttheme li.active > a:not([data-toggle="collapse"]):after, .sidebar .sidebar-wrapper.lighttheme li.active > [data-toggle="collapse"] + div .nav li:after {
  border-right: 17px solid #f4f3ef;
}

.sidebar .sidebar-wrapper.bluelighttheme li.active > a:not([data-toggle="collapse"]):after, .sidebar .sidebar-wrapper.bluelighttheme li.active > [data-toggle="collapse"] + div .nav li:after {
  border-right: 17px solid #eaeef2 !important;
}


.card.darktheme.whitecolor {
  background-color: rgba(29,27,65,.6) !important;
  color: #fff !important;
}

.card.greytheme.greycolor {
  background-color: #282a2e !important;
  color: #fff !important;
}

.card .card-body .card-footer {
  font-family: 'Rubik' !important;
  font-weight: 400;
}

.card .card-header .card-title {
  font-family: 'Rubik' !important;
  font-weight: 400;
}

.sidebar-wrapper {
  font-family: 'Sora' !important;
  font-size: 15px !important;
}

.sidebar .logo, .off-canvas-sidebar .logo {
  font-family: 'Sora' !important;
}


.lighttheme.blackcolor > label.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-shrink.MuiInputLabel-outlined.MuiFormLabel-filled {
  color: #fff !important;
}

a.active.darktheme.whitecolor.nav-link {
  color: #fbc658;
  background: #181745;
}

a.active.greytheme.greycolor.nav-link {
  color: #fbc658;
  background: #282a2e;
}


/* formfield darkthemes */

.formfields.greytheme.greycolor label {
  color: white;
}
.formfields.greytheme.greycolor .MuiInputBase-root {
  color: white;
}

.formfields.greytheme.greycolor .MuiSelect-icon {
  color: rgb(255 255 255 / 54%);
}

.formfields.greytheme.greycolor .MuiOutlinedInput-notchedOutline {
  border-color: rgb(251 251 251 / 23%);
}

.formfields.greytheme.greycolor .e-input-group:not(.e-success):not(.e-warning):not(.e-error):not(.e-float-icon-left),.formfields.greytheme.greycolor .e-input-group.e-float-icon-left:not(.e-success):not(.e-warning):not(.e-error) .e-input-in-wrap,.formfields.greytheme.greycolor .e-input-group.e-control-wrapper:not(.e-success):not(.e-warning):not(.e-error):not(.e-float-icon-left),.formfields.greytheme.greycolor .e-input-group.e-control-wrapper.e-float-icon-left:not(.e-success):not(.e-warning):not(.e-error) .e-input-in-wrap,.formfields.greytheme.greycolor .e-float-input.e-float-icon-left:not(.e-success):not(.e-warning):not(.e-error) .e-input-in-wrap,.formfields.greytheme.greycolor .e-float-input.e-control-wrapper.e-float-icon-left:not(.e-success):not(.e-warning):not(.e-error) .e-input-in-wrap {
  border: 1px solid rgb(251 251 251 / 23%) !important;
  padding: 0.75rem !important;
  border-radius: 5px !important;
  color: white
}



.formfields.darktheme.whitecolor label {
  color: white;
}

.formfields.darktheme.whitecolor .MuiInputBase-root {
  color: white;
}

.formfields.darktheme.whitecolor .MuiSelect-icon {
  color: rgb(255 255 255 / 54%);
}

.formfields.darktheme.whitecolor .MuiOutlinedInput-notchedOutline {
  border-color: rgb(251 251 251 / 23%);
}

.formfields.darktheme.whitecolor .e-input-group:not(.e-success):not(.e-warning):not(.e-error):not(.e-float-icon-left),.formfields.darktheme.whitecolor .e-input-group.e-float-icon-left:not(.e-success):not(.e-warning):not(.e-error) .e-input-in-wrap,.formfields.darktheme.whitecolor .e-input-group.e-control-wrapper:not(.e-success):not(.e-warning):not(.e-error):not(.e-float-icon-left),.formfields.darktheme.whitecolor .e-input-group.e-control-wrapper.e-float-icon-left:not(.e-success):not(.e-warning):not(.e-error) .e-input-in-wrap,.formfields.darktheme.whitecolor .e-float-input.e-float-icon-left:not(.e-success):not(.e-warning):not(.e-error) .e-input-in-wrap,.formfields.darktheme.whitecolor .e-float-input.e-control-wrapper.e-float-icon-left:not(.e-success):not(.e-warning):not(.e-error) .e-input-in-wrap {
  border: 1px solid rgb(251 251 251 / 23%) !important;
  padding: 0.75rem !important;
  border-radius: 5px !important;
  color: white
}

li.MuiButtonBase-root.MuiListItem-root.MuiMenuItem-root.formfields.greytheme.greycolor.MuiMenuItem-gutters.MuiListItem-gutters.MuiListItem-button {
  color: white;
  background: #282a2e;
  margin-top: -10px !important;
  margin-bottom: -10px !important;
  padding-top: 15px !important;
}


li.MuiButtonBase-root.MuiListItem-root.MuiMenuItem-root.formfields.darktheme.whitecolor.MuiMenuItem-gutters.MuiListItem-gutters.MuiListItem-button {
  color: white;
  background: rgb(30 56 93) !important;
  margin-top: -10px !important;
  margin-bottom: -10px !important;
  padding-top: 15px !important;
}



.switchtoggler.greytheme.greycolor .MuiSwitch-colorPrimary.Mui-checked {
  color: #9e9e9e;
}

.switchtoggler.darktheme.whitecolor .MuiSwitch-colorPrimary.Mui-checked {
  color: #9e9e9e;
}

body::-webkit-scrollbar {
  width: 5px;
} 

/* Track */
body::-webkit-scrollbar-track {
  display: none !important;
  background-color: transparent !important;
}
 
/* Handle */
body::-webkit-scrollbar-thumb {
  background: #9e9e9e   !important;
}

/* Handle on hover */
body::-webkit-scrollbar-thumb:hover {
  background: #9e9e9e   !important;
}

.cardtitle-timetitle {
  font-size: 14px;
  font-family: 'Sora' !important;
  font-weight: 500;
}

span.dialtotal {
  font-size: 24px;
  font-weight: 500;
  font-family: 'Sora' !important;
}

.newcolorblue.lighttheme.blackcolor {
  color: blue !important;
}

.newcolorblue.greytheme.greycolor , .newcolorblue.darktheme.whitecolor {
  color: orange !important;
}

.newcolorgreen {
  color: green !important;
}

.modal-backdrop { 
  height: -webkit-fill-available !important;
}

.remote_display{
  font-size: 20px;
}

.remote_display_temp{
  font-size: 40px;
  color:#00ccff
}
.remote_temp_icon{
  font-size: 50px;
}


@media screen and (max-width: 1600px){
  .card-category.newsize {
    font-size: 22px !important;
  }
  
  .newsizetext {
    font-size: 35px !important;
  }

  .card-stats .icon-big {
    font-size: 4em !important;
  }
}

@media screen and (max-width: 1450px){
  .card-category.newsize {
    font-size: 20px !important;
  }
  
  .newsizetext {
    font-size: 32px !important;
  }

  .card-stats .icon-big {
    font-size: 4em !important;
  }
}

@media screen and (max-width: 1370px){
  .card-category.newsize {
    font-size: 19px !important;
  }
  
  .newsizetext {
    font-size: 30px !important;
  }

  .card-stats .icon-big {
    font-size: 4em !important;
  }
}

@media screen and (max-width: 1151px){
  .card-category.newsize {
    font-size: 17px !important;
  }
  
  .newsizetext {
    font-size: 26px !important;
  }

  .card-stats .icon-big {
    font-size: 3em !important;
  }
}

@media screen and (width: 1024px){
  .card-category.newsize {
    font-size: 15px !important;
  }
  
  .newsizetext {
    font-size: 22px !important;
  }

  .card-stats .icon-big {
    font-size: 3em !important;
  }
}

@media screen and (max-width: 991px){
  .card-category.newsize {
    font-size: 22px !important;
  }
  
  .newsizetext {
    font-size: 35px !important;
  }
  .card-stats .icon-big {
    font-size: 4em !important;
  }
}

@media screen and (max-width: 766px){
  .card-category.newsize {
    font-size: 19px !important;
  }
  
  .newsizetext {
    font-size: 28px !important;
  }
  .card-stats .icon-big {
    font-size: 3em !important;
  }
}

@media screen and (max-width: 605px){
  .card-category.newsize {
    font-size: 16px !important;
  }
  
  .newsizetext {
    font-size: 25px !important;
  }
  .card-stats .icon-big {
    font-size: 4em !important;
  }
  .remote_temp_icon{
    font-size: 35px;
  }
  .remote_display_temp{
    font-size: 40px;
    color:#00ccff
  }
}

@media screen and (max-width: 581px){
  .card-category.newsize {
    font-size: 22px !important;
  }
  
  .newsizetext {
    font-size: 35px !important;
  }
  .card-stats .icon-big {
    font-size: 4em !important;
  }
}

@media screen and (max-width: 400px){
  .card-category.newsize {
    font-size: 18px !important;
  }
  
  .newsizetext {
    font-size: 30px !important;
  }
  .card-stats .icon-big {
    font-size: 3em !important;
  }
}

@media screen and (max-width: 321px){
  .card-category.newsize {
    font-size: 17px !important;
  }
  
  .newsizetext {
    font-size: 25px !important;
  }
  .card-stats .icon-big {
    font-size: 4em !important;
  }
}

@media screen and (max-width: 281px){
  .card-category.newsize {
    font-size: 15px !important;
  }
  
  .newsizetext {
    font-size: 20px !important;
  }
  .card-stats .icon-big {
    font-size: 4em !important;
  }
}

.sorafontclass {
  font-family: 'Sora';
  font-weight: 500;
}

button.flexblockbutton {
  font-family: 'Sora' !important;
  font-weight: 300 !important;
  font-size: 11px !important;
}

.flexblock {
  display: flex;
  justify-content: space-between;
}

h5.dashboardcardtitle.card-title {
  font-family: 'Rubik' !important;
}


span.MuiTypography-root.MuiFormControlLabel-label.MuiTypography-body1:first-letter {
  text-transform: lowercase;
}

.makeitupper {
  text-transform: capitalize;
}

.makeitupper:before {
  content: '';
}

.adrcardborder {
  border: 1px solid lightgray;
  border-radius: 20px;
}

/* li.shiftoptions.Mui-selected, li.meteroptions.Mui-selected, li.processoptions.Mui-selected{
  color: white;
  background: green;
}
li.shiftoptions.Mui-selected:hover, li.meteroptions.Mui-selected:hover, li.processoptions.Mui-selected:hover{
  color: white;
  background: green;
}

li.shiftoptions.darktheme.Mui-selected, li.meteroptions.darktheme.Mui-selected, li.processoptions.darktheme.Mui-selected{
  color: white !important;
  background: green !important;
} */

.diagram-component {
  width: 100%;
  height: 500px;
  border: solid 1px black;
  background-color: white;
}

[data-n-id] rect {
  fill: #ffffff;
}

.MuiFormControl-root.MuiTextField-root.customlabelplaceholder.MuiFormControl-fullWidth label.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-outlined {
  transform: translate(14px, -5px) scale(0.75) !important;
  background-color: white;
}

.MuiFormControl-root.MuiTextField-root.customlabelplaceholder.MuiFormControl-fullWidth label.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-outlined.Mui-focused.Mui-focused {
  transform: translate(14px, -5px) scale(0.75) !important;
}

.boardstatus_boardname {
  width: 100% !important;
  display: inline-flex;
  place-content: space-between;
  padding: 0px 0px 0px 10px;
  text-align: start;
  word-break: break-all;
  align-items: center;
}

.boardstatus_boardicon {
  width: 25%;
  align-self: center;
  text-align: center;
}